<template>
  <div>
    <section>
      <hero
        text="Is simply dummy text of the printing and typesetting industry. "
      >
        <img alt="Vue logo" src="@/assets/images/hero.svg" />
      </hero>
    </section>
    <section>
      <div>
        <forms2 message=" Your Message " title="leave your message" />
      </div>
    </section>
    <section>
      <branches />
    </section>
    <section>
      <social />
    </section>
  </div>
</template>
<script>
import Hero from "../components/Hero.vue";
import Forms2 from "@/components/Forms2.vue";
import Branches from "../components/Branches.vue";
import Social from "../components/Social.vue";

export default {
  components: { Hero, Forms2, Branches, Social }
};
</script>
