<template>
  <section>
    <div class="w-3/4 bgmodal flex mx-auto flex-col py-11">
      <div class="flex mx-auto mb-24 text-2xl">{{ title }}</div>
      <div>
        <div class="sm:w-2/5 w-full mb-6 flex mx-auto">
          <input
            type="text"
            :placeholder="$t('forms.fullName')"
            class="placeholder-gray-700 w-full text-center border-b-2 border-secondary-600"
          />
        </div>
        <div class="sm:w-2/5 w-full mb-6 flex mx-auto">
          <input
            type="text"
            :placeholder="$t('forms.yourMail')"
            class="placeholder-gray-700 w-full text-center border-b-2 border-secondary-600"
          />
        </div>
        <div class="sm:w-2/5 w-full mb-6 flex mx-auto">
          <input
            type="text"
            :placeholder="$t('forms.yourPhone')"
            class="placeholder-gray-700 w-full text-center border-b-2 border-secondary-600"
          />
        </div>

        <div
          class="sm:w-2/5 w-full mb-6 flex mx-auto"
          :class="{ hidden: JobTitle }"
        >
          <input
            type="text"
            :placeholder="Job"
            class="placeholder-gray-700 w-full text-center border-b-2 border-secondary-600"
          />
        </div>
        <div
          class="sm:w-2/5 w-full mb-6 flex mx-auto border-b-2 border-secondary-600"
          :class="{ hidden: servicename }"
        >
          service name
        </div>

        <div
          class="sm:w-2/5 w-full mb-6 flex mx-auto"
          :class="{ hidden: Companyname }"
        >
          <input
            type="text"
            :placeholder="Company"
            class="placeholder-gray-900 w-full text-center border-b-2 border-secondary-600"
          />
        </div>

        <div
          class="sm:w-2/5 w-full mb-6 flex mx-auto border-b-2 border-secondary-600"
          :class="{ hidden: UploadFile }"
        >
          <label class="flex mx-auto">
            <img
              class="w-2/3 py-6 cursor-pointer"
              src="@/assets/images/upload.svg"
              alt=""
            />
            <!-- <font-awesome-icon
              :icon="['fas', 'cloud-upload-alt']"
              size="3x"
              class="mx-3 cursor-pointer"
            /> -->

            <input
              type="file"
              :placeholder="Upload"
              class="hidden placeholder-gray-700 w-full text-center"
            />
          </label>
        </div>
        <div class="sm:w-2/5 w-full mb-6 flex mx-auto mt-32">
          <textarea
            :placeholder="message"
            class="placeholder-gray-700 w-full text-center border-b-2 border-secondary-600"
          />
        </div>

        <button
          class="flex mx-auto bg-primary-600 mt-20 px-24 pt-2 rounded-2xl justify-center items-center"
        >
          {{ $t("labels.send") }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    message: { required: true },
    JobTitle: { default: true },
    Job: { required: true },
    Upload: { required: true },
    UploadFile: { default: true },

    Companyname: { default: true },
    Company: { required: true },
    servicename: { default: true },
    title: { required: true }
  }
};
</script>
